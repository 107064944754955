import React from 'react'

const Privacy = () => {
  return (
    <div className='justify-content-center align-items-center d-flex'>
      <div className='container' style={{width:600}}>
          <h1 className='mb-5 mt-5'>Terms and Conditions</h1>

          <p>By accessing the K53 Learners License & Taxi Fare Calculator SA App, you acknowledge that
          you agree to and are bound by the terms of service outlined in the Terms & Conditions below.
          These terms apply to the entire App as well as any communication, including emails, between you and K53 Learners License.</p>

          <h4>Disclaimer of Liability</h4>

          <p>K53 Learners License/Taxi Fare Calculator SA and its team shall not be held liable for any direct,
          indirect, special, incidental, or consequential damages, including but not limited to loss of data or profit,
          arising from the use or inability to use the materials on this site. If you discover an error or inaccuracy,
          please contact us at xulucreatives@gmail.com. However, please note that our team or authorized representatives
          cannot guarantee the correction of such damages. If your use of the materials from this site requires servicing,
          repair, or correction of equipment or data, you assume any associated costs.</p>

          <p>The K53 Learners License/Taxi Fare Calculator SA team will not be held responsible for any outcomes that
          may arise during the use of our resources. We reserve the right to modify prices and revise the policy for
          resource usage at any time.</p>

          <h4>Storage of Test Data</h4>

          <p>The K53 Learners License & Taxi Fare Calculator SA App now uses MongoDB to store user data such as tests and
          chats. We use DeviceID to create and generate user profiles. This data is used solely to display the user's
          progress and enhance their experience within the App. You have the option to delete this stored data as needed.</p>

          <h4>Admob Ads</h4>

          <p>Please note that the K53 Learners License & Taxi Fare Calculator SA App incorporates Admob ads for monetization
          purposes. These ads may be displayed within the App during your usage.</p>

          <h4>In-App Subscriptions</h4>

          <p>We use in-app subscriptions which are recurring every month for paid features. By using the App,
          you automatically agree to this subscription model.</p>
      </div>
    </div>
  )
}

export default Privacy