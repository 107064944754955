import React from 'react'
import Image2 from '../img/xulux_logo-transparent.png'
const Navbar = () => {
  return (
    <div style={{backgroundColor:'#5e9bff'}} >
        <div className='container d-flex justify-content-between align-items-center'>
            <a href='/'><img style={{width:200}} src={Image2} alt='logo'/></a>
            <div className='d-flex align-items-center'>
                <a style={{color:'#ffffff',fontSize:24,fontWeight:'700',textDecoration:'none'}}  href='/#/Privacy'>Privacy</a>
            </div>
        </div>
    </div>
  )
}

export default Navbar