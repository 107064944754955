import React, { Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
import Navbar from './components/Navbar';

function App() {
  return (
    <HashRouter>
      <Suspense>
        <Navbar /> {/* Move the Navbar outside the Routes */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/privacy' element={<Privacy />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;
