import React from 'react'
import Image from '../img/get-it-on-google-play-badge-logo-8CDE582776-seeklogo.com.png'
import Image2 from '../img/AG1.png'
import './showcase.scss'


const ShowCase = (props) => {

  return (
    <div className={['showcase d-md-flex ']+ props.order}>
        <div className='show1 col-md-5 position-relative'>
          <img src={props.phone} alt='App screenshot'/>
          {props.appG && <div className='iframe-container'>
            <iframe  height="230" src="https://www.youtube.com/embed/m6fEmLNcC5Q?si=jXEmsS6M_fO-DXIQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>}

          </div>
        <div className='show2 col-md-7'>
              <div className='info'>
                  <h1>{props.header}</h1>
                  <p>{props.body}</p>
                  <h3>FEATURES 📲</h3>
                  <ul>
                    {props.features.map((item,i) => {
                      return(
                        <li key={i}>🏆{item}</li>
                      )
                    })}

                   </ul>
                  <div className='d-md-flex'>
                    <a href={props.link} target='_blank' rel="noopener noreferrer"><img className="googleplay" src={Image} alt='get it on googleplay'/></a>
                    {props.appG && <a href='https://appgallery.huawei.com/app/C107260885' target='_blank' rel="noopener noreferrer"><img style={{position:'relative',bottom:3}} className="googleplay" src={Image2} alt='Available on AppGallary'/></a>}
                  </div>
              </div>
          </div>
    </div>
  )
}

export default ShowCase