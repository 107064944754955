import React from "react";
import ShowCase from "../components/ShowCase";
import "./home.scss";
import Frame from "../img/learners-frame.png";
import Frame1 from "../img/learners-frame1.png";
import Frame3 from "../img/double.png";
const Home = () => {
  return (
    <div className="home">
      <div className="learners drop-in">
        <div className="container">
          <ShowCase
            link="https://play.google.com/store/apps/details?id=com.xulucreatives.K53Learners"
            phone={Frame}
            header="K53 Learners License Memo"
            body="K53 Learners License Memo (Imbuzi) App is designed to help you learn and pass your K53 learners with ease , as it features all the learning tools provided by the book
                      simplified and easy to learn, it also features short quizzes that allow you to test yourself."
            features={[
              "FUN GAMES & LEADERBOARDS",
              "CHEAT SHEETS",
              "PERSONALIZED TESTS",
              "PINK MODE",
              "QUICK TESTS",
              "FULL LEARNING MEMO",
              "0VER 100+ TEST QUESTIONS",
              "10+ SHORT TESTS",
              "AVAILABLE IN ISIZULU & AFRIKAANS",
              "DASHBOARD TO KEEP TRACK",
              "REVIEW PAST TEST QUESTIONS AND ANSWERS",
              "K53 IMBUZI CHEAT TEST",
              "AUDIO BOOK (AI READ)",
            ]}
            appG={true}
          />
        </div>
      </div>

      <div className="tests drop-in-2">
        <div className="container">
          <ShowCase
            link="https://play.google.com/store/apps/details?id=com.xulucreatives.k53alltests"
            phone={Frame1}
            header="K53 Tests"
            order="showcase--order"
            body="K53 Tests is a simple App designed to allow learners to quickly test themselves on the go."
            features={[
              "FULL LEARNING MEMO",
              "0VER 100+ TEST QUESTIONS",
              "10+ SHORT TESTS",
              "DASHBOARD TO KEEP TRACK",
              "REVIEW PAST TEST QUESTIONS AND ANSWERS",
              "K53 IMBUZI CHEAT TEST",
              "AUDIO BOOK (AI READ)",
            ]}
            appG={false}
          />
        </div>
      </div>
      <div className="taxi-fare drop-in">
        <div className="container">
          <ShowCase
            link="https://play.google.com/store/apps/details?id=com.xulucreatives.taxifarecalc"
            phone={Frame3}
            header="Taxi Fare Calculator SA"
            body="Introducing Taxi Fare Calculator South Africa – your reliable travel companion for hassle-free taxi fare calculations. No more struggles at the driver's seat counting change. With Taxi Fare Calculator, passengers can effortlessly tally the journey costs, ensuring smooth transactions during every South African taxi ride. Say goodbye to guesswork and hello to precision with Taxi Fare Calculator SA.
            "
            features={["Quick Calculations", "Easy To Use"]}
            appG={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
